.title {
  font-weight: 600;
  color: #000000;
  line-height: 1.1;
  &_main {
    font-size: rem(30);
  }
  &_second {
    font-size: rem(21);
  }
}
