.cards {
  padding-top: rem(26);
  padding-bottom: rem(24);
  margin-bottom: rem(24);
  border-bottom: 1px solid #D0D7DD;

  input[type='radio'] {
    display: none;
  }
  span {
    width: 16px;
    height: 16px;
    border: 1px solid #D0D7DD;
    border-radius: 10em;
    display: inline-block;
    min-width: 16px;
  }
  &__title {
    line-height: rem(32);
    font-size: rem(14);
    color: #1F1F22;
    &_line {
      line-height: 1;
    }
  }
  &__desc {
    font-size: rem(12);
    color: #7D838A;
    padding-top: rem(4);
    &_big {
      font-size: rem(14);
    }
  }
  &__text {
    padding-left: rem(8);
  }
  &__label {
    &.active {
      span {
        border: 5px solid #107F8C;
      }
    }
  }
  &__items {
    padding-top: rem(24);
    padding-bottom: rem(24);
    border-bottom: 1px solid #D0D7DD;
  }
  &__item {
    padding-bottom: rem(16);
  }
  &__select {
    width: 100%;
  }
  &__input {
    background: #FFFFFF;
    /* Asphalt / 03 */

    border: 1px solid #D0D7DD;
    box-sizing: border-box;
    width: 100%;
    border-radius: 8px;
    padding: rem(4) rem(12);
    font-size: rem(14);
    color: #000;
    &::placeholder {
      color: #7D838A;
    }
  }
}
