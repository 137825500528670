@import "libs/index";
@import "mixins/mixins";
@import "import/reset";
@import "import/fonts";

body {
  min-width: 320px;
  overflow-x: hidden;
  font-size: 16px;
  font-family: SbSansUI, sans-serif;
}

html,
body {
  height: 100%;
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  &__content {
    flex: 1 0 auto;
  }

  &__footer {
    flex: 0 0 auto;
  }
}

.modal_tovar {
  .modal-dialog {
    max-width: 600px;
  }
}
.modal_card {
  .modal-dialog {
    max-width: 1000px;
    width: 95%;
    margin: 0 auto;
  }
}

.modal-content {
  border-radius: 16px;
  overflow: hidden;
}

.modal-header, .modal-footer {
  background: #F2F4F7;
  border-bottom: none;
  border-top: none;
}

.modal-title {
  color: #1F1F22;
  font-size: rem(21);
  font-weight: 600;
}

.modal-body {
  background: #fff;
  padding: rem(24) rem(32) rem(60);
}

.modal-image {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 150px;
  border-radius: 16px;
  overflow: hidden;

  img {
    max-width: 100%;
    max-height: 100%;
    vertical-align: top;
  }
}

.modal-desc {
  padding-top: rem(24);
  color: #1F1F22;
  padding-bottom: rem(40);

  h3 {
    font-size: rem(16);
    font-weight: 600;
    padding-bottom: rem(8);
  }

  p {
    font-size: rem(14);
    padding-bottom: rem(8);

    &:last-child {
      padding-bottom: 0;
    }
  }
}

.modal {
  &-foot {
    padding: rem(16) 0;
    border-top: 1px solid #D0D7DD;
    border-bottom: 1px solid #D0D7DD;
    flex-wrap: wrap;
  }
  &-right {
    @include media(992) {
      margin-left: auto;
      padding-top: rem(4);
    }
  }

  &-price {
    color: #1F1F22;
    font-weight: 600;
    font-size: rem(16);
    @include media(768) {
      position: relative;
      top: 10px;
    }
  }

  &-select {
    margin-right: rem(16);
    margin-left: rem(16);
    @include media(768) {
      margin-right: 0;
    }
  }

  &-button {
    transition: all .25s;
    color: #fff;
    background: #107F8C;
    border-radius: 16px;
    padding: rem(8) rem(24);
    font-size: rem(14);
    font-weight: 600;
    @include media(768) {
      width: 100%;
      display: block;
    }

    &:hover {
      background: darken(#107F8C, 10%);
    }
    &_white {
      border: 1px solid #D0D7DD;
      background: #fff;
      color: #1F1F22;
      &:hover {
        background: darken(#fff, 5%);
      }
    }
  }
  &-name {
    font-size: rem(16);
    font-weight: 600;
    color: #1F1F22;
  }
  &-subname {
    font-size: rem(14);
    font-weight: 400;
  }
  &-item {
    padding: rem(24) 0;
    border-bottom: 1px solid #D0D7DD;
    @include media(992) {
      flex-direction: column;
      justify-content: stretch !important;
      align-items: stretch !important;
    }
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }
    &_empty {
      padding-top: rem(12);
      padding-bottom: rem(12);
      border-bottom: none;
    }
  }
  &-list {
    &_all {
      padding-top: rem(24);
      padding-bottom: rem(24);
      margin-bottom: rem(24);
    }
  }
  &-delete {
    background-color: transparent;
    border: none;
    svg {
      * {
        transition: all .25s;
      }
      &:hover {
        * {
          fill: #107F8C;
        }
      }
    }
  }
}
select {
  min-width: 100px;
  height: 32px;
  border: 1px solid #D0D7DD;
  box-sizing: border-box;
  border-radius: 8px;
}
.modal_card {
  .modal-price {
    top: 0;
  }
}
.modal-list_all {
  .modal-price {
    top: 0;
  }
}

@import "components/index";
