.tovar {
  margin-top: rem(24);
  border: 1px solid #D0D7DD;
  box-sizing: border-box;
  /* Shadow / 01 */

  filter: drop-shadow(0px 1px 3px rgba(31, 31, 34, 0.25));
  border-radius: 16px;
  overflow: hidden;
  a {
    &:hover {
      color: #000;
    }
  }
  &__top {
    min-height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 100%;
      max-height: 100%;
      vertical-align: top;
    }
  }
  &__bottom {
    padding: rem(16);
    background-color: #fff;
  }
  &__title {
    color: #1F1F22;
    font-size: rem(18);
    line-height: 1.5;
  }
  &__desc {
    font-size: rem(12);
    color: #7D838A;
    padding: rem(16) 0;
  }
  &__card {
    padding: rem(6) rem(16);
    color: #1F1F22;
    font-size: rem(14);
    border: 1px solid #D0D7DD;
    box-sizing: border-box;
    border-radius: 16px;
    text-align: center;
    transition: all .25s;
    background-color: transparent;
    &:hover {
      background-color: darken(#fff, 5%);
    }
  }
  &__price {
    color: #1F1F22;
    font-weight: 600;
    font-size: rem(16);
  }
}
