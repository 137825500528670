.section {
  padding: rem(32) 0 0;
  &:last-child {
    padding-bottom: rem(32);
  }
  &_white {
    background-color: #fff;
  }
}
.footer {
  background: #F2F4F7;
  padding: rem(24) 0;
  &__wrap {
    text-align: right;
    a {
      color: #fff;
      &:hover {
        color: #fff;
      }
    }
  }
  &__button {
    text-decoration: none;
    background: #90D0CC;
    border-radius: 16px;
    border: none;
    padding: rem(8) rem(24);
    color: #fff;
    font-size: rem(14);
    font-weight: 600;
    transition: all .25s;
    &:hover {
      background-color: darken(#90D0CC, 5%);
    }
  }
}
