@font-face {
    font-family: "SbSansUI";
    src: url("../fonts/SBSansUI-Regular.eot");
    src: local("☺"), url("../fonts/SBSansUI-Regular.woff") format("woff"), url("../fonts/SBSansUI-Regular.ttf") format("truetype");
    font-weight: 400;
    font-display: swap;
    font-style: normal;
}
@font-face {
    font-family: "SbSansUI";
    src: url("../fonts/SBSansUI-Semibold.eot");
    src: local("☺"), url("../fonts/SBSansUI-Semibold.woff") format("woff"), url("../fonts/SBSansUI-Semibold.ttf") format("truetype");
    font-weight: 600;
    font-display: swap;
    font-style: normal;
}

