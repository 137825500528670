.top {
  background: #F2F4F7;
  padding: rem(24) 0;
  &__back {
    a {
      font-size: rem(14);
      padding: rem(8) rem(24);
      background-color: #fff;
      border: 1px solid #D0D7DD;
      box-sizing: border-box;
      border-radius: 16px;
      transition: all .25s;
      &:hover {
        color: #1F1F22;
        background-color: darken(#fff, 3%);
      }
    }
    &_link {
      a {
        color: #1358BF;
        padding: 0;
        border: none;
        background: transparent;
      }
    }
  }
  &__title {
    padding-top: rem(4);
    color: #1F1F22;
    font-size: rem(21);
    font-weight: 600;
  }
  &__links {
    padding: rem(2);
    border-radius: 16px;
    background: #fff;
    margin-left: rem(16);
    @include media(768) {
      margin-top: rem(16);
      margin-left: 0;
    }
  }
  &__link {
    padding: rem(6) rem(16);
    background-color: #fff;
    border-radius: 16px;
    color: #1F1F22;
    display: inline-block;
    font-size: rem(14);
    transition: all .25s;
    &:hover {
      color: #1F1F22;
    }
    &.active {
      background-color: #D0D7DD;
    }
  }
}
