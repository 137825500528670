.header {
  background: #FFFFFF;
  padding: rem(10) 0;
  position: relative;
  &__logo {
    svg {
      max-width: 100%;
      height: auto;
      vertical-align: middle;
    }
  }
  &__search {
    width: 100%;
    margin-left: rem(16);
    cursor: pointer;
    max-width: 350px;
    span {
      display: inline-block;
      margin-left: rem(8);
      font-size: rem(14);
      @include media(768) {
        display: none;
      }
    }
  }
  &__card {
    text-align: right;
  }
}
.search {
  &__icon {
    margin-left: rem(10);
  }
  &__wrap {
    width: 0;
    overflow: hidden;
    transition: all .25s;
    &.active {
      width: 100%;
    }
    input {
      width: 100%;
      font-weight: 400;
      padding: rem(6) rem(10);
      font-size: rem(14);
      outline: none;
      border-radius: 16px;
      border: 1px solid lighten(#000, 15%);
    }
  }
}
